import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const SchoolInfosNavigation = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    // Attacher l'événement de redimensionnement lors du montage
    window.addEventListener('resize', handleResize)

    // Détacher l'événement de redimensionnement lors du démontage
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
        kitpromoImage: file(relativePath: { eq: "festifAtSchool/infos/kitpromo.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        activitesImage: file(relativePath: { eq: "festifAtSchool/infos/activites.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        tachesImage: file(relativePath: { eq: "festifAtSchool/infos/taches.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
    }
  `)

  const kitpromoImage = getImage(data.kitpromoImage)
  const activitesImage = getImage(data.activitesImage)
  const tachesImage = getImage(data.tachesImage)

  let index = 0
  const getNextColor = () => {
    const bgArray = ['bg-pink-school', 'bg-yellow-school', 'bg-blue-school']
    return bgArray[(index++) % bgArray.length]
  }

  const getNavButton = (title, internalLink, imageSrc, imageAlt, longText = false) => {
    return (
            <li className={`w-[150px] h-[150px] rounded-3xl m-[10px] ${getNextColor()} hover:opacity-80`}>
                <a rel="noopener noreferrer" href={internalLink}>
                    <div className="m-[15px] !text-center !align-center">
                        <div className="mt-[23px] mb-[8px]">
                            <GatsbyImage image={imageSrc} alt={imageAlt} className={`w-[60px] ${longText ? 'h-[40px]' : 'h-[65px]'}`} imgStyle={{ objectFit: 'contain' }}/>
                        </div>
                        <div className={`font-openSans font-bold tracking-[-2px] text-xl uppercase text-white inline-block relative leading-none ${longText ? 'h-[54px] leading-tight' : 'h-[24px] mt-[5px]'}`}>
                            {title}
                        </div>
                    </div>
                </a>
            </li>
    )
  }

  return (
        <>
            <div className='pb-12'>
                <h1>Préparation</h1>
            </div>
            <nav className='school-preparation-navigation pb-12'>
                <ul className='flex justify-center items-center flex-wrap max-w-[1000px] mx-auto' id="info_content">

                    { getNavButton('Kit promo', '/festif-a-lecole/preparation/kitpromo#info_content', kitpromoImage, 'Logo Kit promo') }
                    { getNavButton('Activités', '/festif-a-lecole/preparation/activites#info_content', activitesImage, 'Logo Activités') }
                    { getNavButton('Tâches préparatoires', '/festif-a-lecole/preparation/taches#info_content', tachesImage, 'Logo Tâches') }

                </ul>
            </nav>
        </>
  )
}

export default SchoolInfosNavigation
